/* Set custom system values */
$primary: #5cb739;
$primary-light: #71b756;

$gd-lighter: #ecebde;
$gd-light: $primary-light;
$gd-green: $primary;
$gd-dark: #333f2e;
$gd-dark-highlight: #475243;
$gd-dark-shadow: #2c3627;

$wrapper-background: #fff;
$nav-background-color: $gd-dark;
$nav-logo-background: $gd-lighter;

$content-nav-background: #fafafa;

$top-bar-background: $gd-dark;
$top-bar-button-border-color: rgba(255, 255, 255, 0.1);

$header-background: $gd-lighter;

/* Import Primary Spadmin stylesheet. */
@import "../../../../vendor/pump/spadmin/src/assets/scss/backend.scss";
/* Import common Manager styles */
@import "../../../../yii-common/assets/scss/manager.scss";

/* Custom to this SPAdmin instance */
body {
  #wrapper {
    #login {
      background-color: $gd-lighter;
    }

    #nav .nav-inner .logo {
      background-color: $gd-lighter;
      height: 87px;
      padding: 8px 20px;

      img {
        max-width: 208px;
        margin: 0px auto;
        max-height: 100%;
      }
    }

    /* Custom */
    #nav {
      @media only screen and (max-width: 1000px) {
        .primary-nav {
          flex-grow: unset;
        }
      }
    }

    #nav .nav-inner .secondary-nav {
      width: 100%;
      padding-top: 10px;
      @include proxima-nova(600);
      li {
        a {
          color: $pale-blue;
          padding: 10px 14px 11px 32px;
          display: block;
          @include standard-transition;
          i {
            margin-right: 16px;
            @include text-rem(16);
          }
          &:hover,
          &.active {
            color: white;
            background: $gd-dark-highlight;
          }
        }

        &.content {
          color: $pale-blue;
          padding: 20px 10px;
          border-top: 1px solid $trans-white;

          p {
            margin: 0 0 1rem 0;
            line-height: 1.2rem;
          }

          i {
            font-size: inherit;
          }
        }
      }
    }

    #body-content {
      .copy {
        @include copy-styles;
      }
    }
  }
}

div.stripe-card-element {
  border: 1px solid rgb(223, 226, 229);
  border-radius: 4px;
  margin: 0px 14px 22px;
  padding: 8px 15px 9px;
}

.unit-price-well {
  border: 1px solid rgb(223, 226, 229);
  background-color: #dff6ec !important;
  font-family: "proxima-nova", sans-serif;
  color: #354052;
  font-size: 14px;
}

.container-widget.estimate_notes,
.container-widget.estimate_details {
  background-color: $gd-lighter;
  border-color: $primary;
  .container-widget-inner {
    > div {
      margin: 0.75rem;
      margin-top: 0;

      h2 {
        color: $gd-dark;
        margin-bottom: 0.75rem;
      }
    }

    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}

#Addonslist,
#LocationServiceslist {
  input {
    border: 1px solid black;
    -webkit-appearance: auto;
    float: left;
  }
  label {
    line-height: 1.5rem;
    padding-left: 2rem;
  }
}

.package-schedule-well {
  border: 1px solid rgb(223, 226, 229);
  background-color: #dff6ec !important;
  padding: 1rem;
  .schedule-price {
      font-size: 3rem;
  }
}

.before-discount-well {
  border: 1px solid rgb(223, 226, 229);
  padding: 1rem;
  background-color: #eee !important;
  text-align: center;
}

.schedule-price-no-discount {
    font-size: 2rem;
}

.full-width-table {
    width: 100%;

    td {
        padding:10px 0;
    }
    tr {
        border-bottom: 1px solid rgb(223, 226, 229);
    }
}

input#manager-button {
    border: none;
    float: none;
    display: block;
    color: #fff;
    outline: none;
    width: 343px;
    margin: 0 auto;
    border-radius: 25px;
    padding: 13px 50px;
    font-family: futura-pt,sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 93.75%;
    letter-spacing: 2px;
    background: #1e5799;
    background: linear-gradient(180deg,#1e5799 0,#e78700 0,#ffa600 0,#e78700);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e5799",endColorstr="#e78700",GradientType=0);
    cursor: pointer;
    margin-top: 2rem;
}

.gd-dash {
  h1 {
    font-size: 2em;
    padding-bottom: 40px;
  }
  .gd-dash-banner {
    width:100%;
    padding-bottom: 40px;
  }

  .grid {
    div {
      div {
        background:#eef7eb;
        color:#333f2e;
        border-radius: 15px;

        a {
          display:block;
          padding:40px 40px 0;
          color:#333f2e;
          text-decoration:none;
          font-size:1.5em !important;

          &+a {
            padding:0 40px 40px;
            font-size:1.2em !important;
          }
        }
      }
    }
  }

  .flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &>div {
      flex: 1 1 33%;
      width:33%;
      max-width:33%;
      margin-bottom:10px;
      div {
        background:#eef7eb;
        color:#333f2e;
        border-radius: 15px;
        border:4px solid #fff;

        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          text-align: center;
          flex: 1 1 100%;
          color:#333f2e;
          text-decoration:none;
          padding: 30px 0;

          span {
            font-size:4em !important;
          }
          span + span {
            padding-top:10px;
            font-size:1.5em !important;
          }
        }

        &:hover {
          background:#fff;
          border:4px solid #eef7eb;

        }
      }
    }

    @media only screen and (max-width: 767px) {
      &>div {
        flex:1 1 50%;
        width:50%;
        max-width:50%;
      }
    }

  }

}

#body-content {
  overflow: scroll;
}
#main {
  transition: opacity 0.35s, margin 0.35s;
}

@media only screen and (max-width:767px) {
  .hide-mobile {
    display:none !important;
  }
}

.resend_disabled {
  position: relative;
  z-index: 9;
  &:hover:after {
    content: "Batch resend should be limited to: \a More than 0 \a Less than 400";
    white-space: break-spaces;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    background-color: #ffdfdc;
    color: black;
    padding: 6px;
    border: 2px solid #b61100;
  }
}

.outstanding-quotes-filter{
  margin-right: auto;
}